.App {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100vh;
    background-color: rgba(220, 220, 220, 0.6);
    z-index: 1000;
  }
  
  /* Spinner Styles */
  .loadingContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    border: 16px solid #060606;
    border-radius: 50%;
    border-top: 16px solid var(--lgmd-color-primary);
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Loaded Data Styles */
  .data-container {
    font-size: 24px;
    color: #333;
  }
  
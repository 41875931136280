.admincontainer{
    min-height: 70vh;
}
.adminsidebarcontainer{
    height: 20vh;
    background-color: var(--lgmd-color-primary);
}
.adminsidebarcontainer{
    height: 20vh;
    background-color: var(--lgmd-color-primary);
}
.adminsidebarcontainer ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding-left: 0;
    list-style: none;
}
.adminsidebarcontainer ul li{
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid rgb(214, 214, 214);
}

.adminsidebarcontainer ul li:hover , .adminsidebarcontainer ul li.active{
    background-color:#9c8933 ;
    color: white;
}
.newCategory input ,.category input , .newCategory textarea,.category textarea{
    margin: 10px 10px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid lightgray;
}
.fileupload{
    border: 1px dashed lightgray;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}
.fileupload .selectedimage{
    width: 100px;
    height: 100px;
    border: 1px solid rgb(179, 179, 179);
}
.selectedimage img{
    width: 100%;
    height: 100%;
}
.selectedimage{
    position: relative;
    border-radius: 9px;
    overflow: hidden;
}
.selectedimage span{
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.1s linear;
}
.selectedimage span:hover{
   transform: scale(1.3);
}
.newCategory button ,.category button{
    width: 150px;
    margin:30px 10px ;
    border: none;
    padding: 10px 20px;
    background-color: var(--lgmd-color-primary);
    color: white;
    transition: all 0.1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.newCategory button:nth-child(2) ,.category button:nth-child(2){
    background-color: lightgray;
    color: black;
}

.productsizes{
    display: flex;
    flex-direction: column;
    margin: 30px 10px;
}
.productsizes span{
    text-align: start;
}
.sizes{
    display: grid;
    grid-template-columns: repeat(5,1fr);
}
.newCategory button:hover,.productbutton:hover{
    transform: scale(1.07);
}

.newCategory input:focus,.category input:focus , .newCategory textarea:focus ,.category textarea:focus{
    outline: none;
}

input[type='checkbox']{
    margin: 0;
    padding: 20px;
}
.productactions{
    border-bottom: 1px solid lightgray;
    padding: 10px;
}
.productactions button ,.productactions input{
    border: none;
    padding: 5px 20px;
}
.productactions input{
    border: 1px solid rgb(116, 116, 116);
}
.founditem{
    width: 100%;
    padding: 10px 30px;
    border: 1px solid lightgray;
    margin-top: 10px;
    border-radius: 10px;
    text-align: start;
    transition: all 0.2s linear;
}

.founditem span:first-child{
    font-size: 18px;
    font-weight: bold;
}
.founditem span:nth-child(2){
    font-size: 14px;
    font-weight: lighter;
    color: lightgray;
}

.founditemaction span{
    font-size: 16px!important;
    font-weight: bold!important;
    color: black!important;
    cursor: pointer;
    transition: all 0.2s linear;
}
.founditemaction span:hover{
    transform: scale(1.3);
}
.customcCheckbox{
    width: 0;
    height: 0;
    cursor: pointer;
    padding: 10px;
    line-height: 0px;
    border: 1px solid lightgray;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.customcCheckbox svg{
    display: none;
    position: absolute;
}

.customcCheckbox.checked{
    background-color: blue;
}
.customcCheckbox.checked svg{
    display: flex;
}


.customcCheckbox.checked::after{
    position: absolute;
    content: '';
}